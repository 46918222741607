import {getCoords, getAngle, calcHypotenuse} from './angles';
import {h, resolveComponent} from 'vue';
import isEmpty from 'lodash/isEmpty';

export function compilerV1 (flow, key, accumulator = []) {
  if (!key) return accumulator;

  let {events, coords, component, width, viewState} = flow[key];
  let compiled;
  let style = {
    position: 'absolute',
    width: width ? `${width}px` : 'auto',
  };

  style = getCoords(coords, style);
  component = resolveComponent(component) || component;

  let props = {
    id: key,
    uid: key,
    value: flow[key],
    style,
    viewState,
  };

  accumulator.push(h(component, props));

  if (events) {
    compiled = events.reduce((accumulator, item) => {
      if (!item.next || !(item.next in flow)) {
        return accumulator
      } else {
        let newLine = resolveComponent('Line');
        let [, y1, x1] = item.coords;
        let [, y2, x2] = flow[item.next].coords;
        let angle = getAngle(x1, y1, x2, y2 + 12);
        let width = calcHypotenuse(x1, y1, x2, y2 + 12)
        let style = {
          width: `${width}px`,
          transform: `rotate(${angle}deg)`,
          position: 'absolute',
          'border-style': 'dashed'
        };
        style = getCoords(item.coords, style);
        accumulator.push(h(newLine, {style}));
      } 
      return accumulator
      
    }, accumulator)
  } else {
    compiled = null
  }

  return compiled ? compiled : accumulator;
}

export function compilerV2 (flow, key, accumulator = []) {
  if (!key) return accumulator;

  let {meta: {coords, component, viewState}, on} = flow[key];
  let compiled;
  let style = {
    position: 'absolute'
  };

  style = getCoords(coords, style);
  component = resolveComponent(component) || component;

  let props = {
    id: key,
    uid: key,
    value: flow[key],
    style,
    viewState,
  };

  accumulator.push(h(component, props));

  if (!isEmpty(on)) {
    compiled = Object.keys(on).reduce((accumulator, key) => {
      const event = on[key];
      if (!event.next || !(event.next in flow)) {
        return accumulator
      } else {
        let newLine = resolveComponent('Line');
        let [, y1, x1] = event.meta.coords;
        let [, y2, x2] = flow[event.next].meta.coords;
        accumulator.push(h(newLine, {x1, x2, y1, y2, next: event.next}));
      } 
      return accumulator
      
    }, accumulator)
  } else {
    compiled = null
  }

  return compiled ? compiled : accumulator;
}