import { h } from "@vue/runtime-core";
import {getPositionStyle} from './lib/position';
import {_poppers, _showing} from './index'

export default {
  data: () => ({
    styles: {}
  }),
  computed: {
    showing: () => _showing,
    poppers: () => _poppers
  },
  render () {
    return Object.keys(this.showing).map((key) => {
      let el = this.showing[key];
      el = el.nodeType === 3 ? el.nextElementSibling : el;
      const rect = el.getBoundingClientRect().toJSON();

      this.styles[key] = getPositionStyle(this.poppers[key].position, rect)      

      return typeof this.poppers[key].popper === 'function' ? h('div', {
        style: this.styles[key]
      }, this.poppers[key].popper({})) : null
    })
  }
}
