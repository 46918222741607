import {registerPopper, showPopper} from './index';

export default {
  props: {
    position: {
      type: String,
      default: 'bottom-start'
    }
  },
  data: () => ({
    showing: false
  }),
  unmounted() {
    showPopper(this._.uid, this.$el, false)
  },
  watch: {
    showing (val) {
      showPopper(this._.uid, this.$el, val);
    }
  },
  methods: {
    showPopper (show) {
      if (typeof show !== 'boolean') {
        return this.showing = !this.showing
      }

      return this.showing = show
    }
  },
  render () {
    registerPopper(this._.uid, {popper: this.$slots.popper, position: this.position});
    return this.$slots.default({
      showPopper: this.showPopper,
      showing: this.showing
    });
  }
}