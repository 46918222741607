<template>
  <div v-if="readOnly" ref="node" class="node pad--all-sm origin">
   <img v-if="isStart" src="@/assets/icons/crown.svg" class="node-start node-svg abs--top-left" />
    <p class="node-name typ--ii typ--500" ref="content">{{vnode.name && vnode.name.length ? vnode.name : vnode.meta.defaultName}}</p>
    <ul class="node-events rst--list pad--left-xs pad--top-xs">
      <li :id="key" class="node-events-item typ--iv-1 flx origin pad--right-md" :key="key" v-for="(event, key) in events">
        <p class="node-events-item-action ofst--right-xs">{{event.name ? event.name : event.meta.defaultName}}</p>
        <div v-if="event.next" class="flx-fill origin ovr--hidden">
          <hr class="node-events-item-hr fll--parent-w abs--center-center" />
        </div>
        <button class="node-events-item-new rst--button abs--center-right">
          <Dot v-if="event.next" class="node-svg" />
        </button>
      </li>
    </ul>
  </div>

  <div v-else ref="node" class="node pad--all-sm origin" :class="{isOver}" @dblclick.stop.prevent="handleDblClick" @mousedown="handleMouseDown">
    <img v-if="isStart" src="@/assets/icons/crown.svg" class="node-start node-svg abs--top-left" />
    <p class="node-name typ--ii typ--500" ref="content" :class="{isNotSelected: highlightAction(uid)}" @keydown="editContent($event, 'name')" :contenteditable="editing">{{vnode.name ? vnode.name : vnode.meta.defaultName}}</p>
    <ul ref="on" v-show="viewState !== 'drawing'" class="node-events rst--list pad--left-xs pad--top-xs origin">
      <li :id="key" class="node-events-item typ--iv-1 flx origin pad--right-md" :class="{isNotSelected: highlightAction(event.next)}" :key="key" v-for="(event, key) in events">
        <p class="node-events-item-action ofst--right-xs" :ref="'event_name' + key" @keydown="editContent($event, 'event', key)" :contenteditable="editing">{{event.name ? event.name : event.meta.defaultName}}</p>
        <div v-if="event.next || isHover" class="flx-fill origin ovr--hidden">
          <hr class="node-events-item-hr fll--parent-w abs--center-center" />
        </div>
        <button v-if="editing" class="rst--button ofst--left-sm" @mousedown.stop.prevent="deleteEvent(key)">&times;</button>
        <Popper position="top">
          <template v-slot="{showPopper}">
            <button v-show="!editing" :id="uid + '_event_anchor' + key" :ref="'event_anchor' + key" class="node-events-item-new rst--button abs--center-right" @mouseenter="showPopperDelay(showPopper, true)" @mouseleave="showPopperDelay(showPopper, false)" @mousedown.stop="handleDrawNode($event, key)">
              <Dot v-if="event.next" class="node-svg" />
              <Plus v-else-if="isHover" class="node-svg" />
              <!-- <img v-if="event.next" class="node-svg" :src="Dot" alt=""> -->
              <!-- <img v-else-if="isHover" class="node-svg" :src="Plus" alt=""> -->
            </button>
          </template>
          <template v-slot:popper>
            <p class="tooltip"><small>Click and drag to create new node</small></p>
          </template>
         </Popper>
      </li>
      <li class="node-add flx ovr--ellipsis" :class="{isHover}">
        <Popper position="top">
          <template v-slot="{showPopper}">
            <button @mouseenter="showPopperDelay(showPopper, true)" @mouseleave="showPopperDelay(showPopper, false)" class="node-events-add rst--button ofst--right-sm" @mousedown.stop.prevent="addEvent(); showPopperDelay(showPopper, false)">
              <Plus class="node-svg flt--left" />
              <!-- <span class="typ--iv-1"></span> -->
            </button>
          </template>
          <template v-slot:popper>
            <p class="tooltip"><small>New action</small></p>
          </template>
        </Popper>
      </li>
    </ul>
    <!-- <button v-show="editing" class="rst--button abs--top-right typ--iv-1 ofst--top-xs ofst--right-xs" @click="save">Save</button> -->
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import Plus from '@/assets/icons/plus.svg?inline';
import Dot from '@/assets/icons/dot.svg?inline';
import {clearSelection} from '@/lib/clearSelection.js';
import {generateUid} from '@/lib/generate-uid';
import event from '@/models/event'
import Popper from '@/components/Popper/popper';

const defaultEventText = 'ACTION ';

export default {
  components: {Popper, Dot, Plus},
  data: () => ({
    editEventIndex: null,
    showEdit: false,
    showingActionPopper: null,
  }),
  props: {
    value: [Object],
    uid: String,
    viewState: String,
  },
  computed: {
    vnode: {
      get () {
        return cloneDeep(this.value)
      }
    },
    readOnly() {
      return this.$store.getters['flow/readOnly']
    },
    editing () {
      return this.$store.state.flow.editingNodeUid === this.uid
    },
    cursorState () {
      return this.$store.state.cursorState;
    },
    isOver () {
      const selectedUids = this.$store.getters.selectedUids;
      const isOver = this.$store.state.mouseOver === this.uid;
      return isOver && !selectedUids.includes(this.uid);
    },
    selectedUids () {
      return this.$store.getters.selectedUids;
    },
    isHover () {
      return this.$store.state.mouseOver === this.uid;
    },
    isStart () {
      return this.$store.getters['flow/flow'].start === this.uid
    },
    events () {
      let index = 0;
      const events =  Object.keys(this.vnode.on).map((uid) => {
          if (this.vnode.on[uid].meta.sort === undefined) {
            this.vnode.on[uid].meta.sort = index;
            index++;
          }
          return uid
        }).sort((uidA, uidB) => this.vnode.on[uidA].meta.sort - this.vnode.on[uidB].meta.sort).reduce((accumulator, uid) => {
        accumulator[uid] = this.vnode.on[uid]
        return accumulator
      }, {});

      if (index) {
        this.save()
      }

      return events;
    },
    offset () {
      return this.$store.state.offset
    },
    scrollPosition () {
      return this.$store.state.scroll
    }
  },
  mounted () {
    this.updateAnchorCoords();
  },
  unmounted() {
    window.removeEventListener('click', this.clickOutside)
  },
  methods: {
    clickOutside ({target}) {
      this.$nextTick(() => {
        if (this.$refs.node && target === this.$refs.node || this.$refs.node.contains(target)) {
          return
        }
        this.save();
      })
    },
    highlightAction (next) {
      if (this.selectedUids.length == 0) {
        return false
      }

      if (this.cursorState !== 'dragging') {
        return false
      }

      if (this.isOver) {
        return false;
      }

      return !this.selectedUids.includes(next);
    },
    showPopperDelay (showPopper, show) {
      if (show) {
        this.showingActionPopper = setTimeout(() => showPopper(true), 1000);
      } else {
        if (this.showingActionPopper) {
          clearTimeout(this.showingActionPopper);
          showPopper(false)
        }
      }
    },
    addEvent () {
      const {on} = this.vnode;
      const new_event = cloneDeep(event);
      new_event.meta.defaultName = defaultEventText + (Object.keys(on).length + 1);
      new_event.meta.sort = Object.keys(on).length;
      const uid = generateUid();
      on[uid] = new_event;
      this.save();
    },
    dragStart (e) {
      if (!this.editing) {
        this.$emit('dragStart', e, this.uid)
      }
    },
    deleteEvent (key) {
      this.$store.dispatch('flow/updateNode', {ref: `${this.uid}.on.${key}`, del: true})
    },
    handleDblClick ({target}) {
      if (this.editing) {
        return
      }
      this.$store.commit('flow/setEditingNodeUid', this.uid);
      window.addEventListener('click', this.clickOutside)
      this.$nextTick(() => {
        if (target.isContentEditable) {
          if (this.$refs.on.contains(target)) {
            const key = target.parentNode.id;
            if (target.innerText === this.vnode.on[key].defaultName) {
              target.innerText = ''
            }
          }
          if (target.innerText === this.value.defaultName) {
            target.innerText = ''
          }
          target.focus();
        }
      })
    },
    handleMouseDown (e) {
      if (this.editing) {
        e.stopPropagation();
      } else {
        this.$emit('dragStart', e, this.uid)
      }
    },
    editContent (e, type, event_uid) {
      let {target, key} = e;
      
      window.requestAnimationFrame(() => {
        switch(type) {
          case 'name':
            this.vnode.name = target.innerText.trim();
          break;

          case 'event':
            this.vnode.on[event_uid].name = target.innerText.trim()
            break;
        }

        if (key === 'Enter' || key === 'Tab') {
          e.preventDefault();
          this.save();
          target.blur();
          return 
        }
      })
    },
    updateAnchorCoords () {
      return new Promise((res) => {
        let {on} = this.vnode;
        this.$nextTick(() => {
          on = Object.keys(on).reduce((on, uid) => {
            const ref = 'event_anchor' + uid;

            if (!(ref in this.$refs) && !this.$refs[ref]) {
              return on
            }

            let {x, y, width, height} = this.$refs[ref].getBoundingClientRect().toJSON();
            x = x + this.scrollPosition.left - this.offset.left + width/2;
            y = y + this.scrollPosition.top - this.offset.top + height/2;
            on[uid].meta.coords = ['top left', y, x];
            return on;
          }, on);
          res(on)
        });
      })
    },
    save () {
      window.removeEventListener('click', this.clickOutside);
      this.$store.commit('flow/setEditingNodeUid', null);
      this.updateAnchorCoords().then((on) => {
        this.vnode.on = on
        this.$store.dispatch('flow/updateNode', {ref: this.uid, updates: this.vnode}).then(() => {
          clearSelection();
        }) 
      });
    },
    handleDrawNode ({target}, key) {
      let {x, y, width, height} = target.getBoundingClientRect().toJSON();
      x = x + width/2;
      y = y + height/2;
      this.$store.dispatch('flow/createNode', {x, y, key, uid: this.uid})
    }
  }
}
</script>

<style lang="scss" scoped>
.node {
  cursor: default;
  padding-right: 2rem;
  border-radius: .25rem;
  border: solid thin transparent;
  min-width: 7rem;
  min-height: 4rem;

  &.isOver {
    border-color: rgba(0,0,0,0.05);
  }

  &-start {
    transform: translate(-50%, .5rem);
    background-color: #fff;
  }

  &-name {
    white-space: nowrap;

    &.isNotSelected {
      color: #aaaaaa;
    }
  }


  &-events {
    min-height: 1rem;
    font-family: 'IBM Plex Mono', monospace;

    &-item {
      white-space: nowrap;
      position: relative;
      padding: .5rem 1rem .5rem 1rem;
      min-width: 100%;
      text-transform: uppercase;

      &:not(:hover).isNotSelected {
        color: #aaaaaa;
      }


      &-action {
        background-color: #fff;
      }

      &-anchor {
        opacity: .5;
        transform-origin: center center;
        transform: scale(1);

        &:hover {
          opacity: 1;
          transform: scale(1.25);
        }
      }
      
      &-hr {
        margin: 0;
        border: 0;
        border-bottom: solid thin gray;
      }

      &-new {
        padding: .1rem;
        border-radius: .25rem;
        background-color: transparent;
        transition: background-color .15s ease;
        cursor: url(~@/assets/icons/cursor-plus.svg) 4 12, default;

        &:hover {
          background-color: #eaeaea;
        }
      }


      &-svg {
        width: 10px;
        height: 10px;
      }
    }

    &-add {
      text-transform: uppercase;
      letter-spacing: 0.1em;
      font-size: 0.8em;
      padding: .1rem;
      border-radius: .25rem;
      background-color: transparent;
      transition: background-color .15s ease;
      cursor: url(~@/assets/icons/cursor-plus.svg) 4 12, default;

      &:hover {
        background-color: #eaeaea;
      }
    }

    &-item {
      &::before {
        content: '';
        height: 100%;
        width: .5rem;
        display: inline-block;
        border: solid thin gray;
        border-right-color: transparent;
        border-top-color: transparent;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(0, -50%);
      }

      &:first-of-type:before {
        height: 50%;
        transform: translate(0, -15%);
      }
    }
  }

  &-svg {
    display: block;
    pointer-events: none;
    width: 12px;
    height: 12px;
  }

  &-add {
    opacity: 0;
    pointer-events: none;
    transition: opacity .05s ease-in-out 0s;
    transform: translate(.25rem, 0);
    margin-left: -.25rem;
  }

  &-add.isHover {
    transition-delay: 0s;
    opacity: 1;
    pointer-events: all;
  }

  [contenteditable="true"] {
    padding: .25em;
    margin: -.25em;
    background: #eee;
    box-shadow: 0 0 0 1px #ddd;
  }
}
</style>