export {default as Poppers} from './poppers.js';
export {default as Popper} from './popper.js';
import { reactive } from '@vue/reactivity';

export const _poppers = reactive({});
export const _showing = reactive({});

export function registerPopper (id, slot) {
  _poppers[id] = slot;
}

export function showPopper (id, $el, show) {
  if (id in _showing || !show) {
    delete _showing[id]
  } else {
    _showing[id] = $el;
  }
}