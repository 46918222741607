export function calcHypotenuse(x1, y1, x2, y2) {
  let a = x2 - x1;
  let b = y2 - y1;
  return (Math.sqrt((a * a) + (b * b)));
}

export function getAngle (x1, y1, x2, y2) {
  return Math.atan2(y2 -y1, x2 - x1) * 180 / Math.PI;
}

export function getCoords (coords, style = {}) {
  coords = coords.slice();
  let orientation = coords.shift();

  return orientation.split(' ').reduce((style, pos, i) => {
    style[pos] = `${coords[i]}px`;
    return style;
  }, style);
}