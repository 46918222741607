<script>
import {h} from 'vue';
import {compilerV2} from '@/lib/compilers';
import Node from './Node';
import Line from './Line';
import isEmpty from 'lodash/isEmpty';

export default {
  props: {
    flow: Object,
  },
  components: {
    Node,
    Line
  },
  mounted () {
    this.$emit('ready', this.$el);
  },
  render () {
    if (!this.flow || isEmpty(this.flow)) {
      return h('div', {
        class: 'origin',
        style: {
          width: '100vw',
          height: '100vh'
        },
      } , [h(
        'p',
        {
          class: "abs--center-center"
        },
        ''
      )]);
    }
  
    let children = Object.keys(this.flow).map(key => compilerV2(this.flow, key));

    return h('div', {
      style: {
        width: '3200px',
        height: '1600px'
      },
    }, ...children);
  }
}
</script>