<template>
  <svg class="line" :width="width" :height="height" :style="style">
    <defs>
      <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="8" refY="3" fill="#767676" orient="auto">
        <polygon points="0 0, 8 3, 0 6" />
      </marker>
    </defs>
    <path fill="none" :stroke="strokeColor" stroke-width="1" :d="d" marker-end="url(#arrowhead)"/>
    Sorry, your browser does not support inline SVG.
  </svg>
</template>

<script>

export default {
  props: {
    x1: Number,
    x2: Number,
    y1: Number,
    y2: Number,
    next: String
  },
  computed: {
    d () {
      const x1 = this.x2 > this.x1 ? 0 : this.width;
      const x2 = this.x2 > this.x1 ? this.width : 0;
      const y1 = this.y2 > this.y1 ? 0 : this.height - 18;
      const y2 = this.y2 > this.y1 ? this.height : 18;

      if (this.x2 > this.x1) {
        return `M${x1} ${y1} L${x2} ${y2}`
      }

      const dx1 = this.x2 > this.x1 ? this.width/2 + this.height/2 : x1
      const dy1 = this.x2 > this.x1 ? y1 : this.width/2 + this.height/2;

      const dx2 = this.x2 > this.x1 ? this.width/2 + this.height/2 : x2; 
      const dy2 = this.x2 > this.x1 ? y2 : (this.width/2 + this.height/2);
      
      return `M ${x1} ${y1} C ${dx1} ${dy1}, ${dx2} ${dy2}, ${x2} ${y2}`
    },
    isOver () {
      const isOver = this.$store.state.mouseOver === this.next || this.$store.getters.selectedUids.includes(this.next);
      return isOver;
    },
    strokeColor () {
      return this.isOver ? '#999999' : '#d3d3d3'
    },
    width () {
      return Math.abs(this.x2 - this.x1)
    },
    height () {
      return Math.abs(this.y2 - this.y1) + 18;
    },
    line_x1 () {
      return this.x2 > this.x1 ? 0 : this.width;
    },
    line_x2 () {
      return this.x2 > this.x1 ? this.width : 0
    },
    line_y1 () {
      return this.y2 > this.y1 ? 0 : this.height;
    },
    line_y2 () {
      return this.y2 > this.y1 ? this.height : 0
    },
    style () {
      return {
        top: `${this.y2 > this.y1 ? this.y1 : this.y2}px`,
        left: `${this.x2 > this.x1 ? this.x1 : this.x2}px`,
        position: 'absolute'
      }
    }
  }
}
</script>

<style class="scss" scoped>
.line {
  z-index: -1;
  overflow: visible;
}
hr {
  z-index: -1;
  transform-origin: left;
  margin: 0;
  border: 0;
  border-bottom: solid thin #d3d3d3;
}
</style>