export function getPositionStyle (position, rect) {
  switch (position) {
    case 'bottom-start':
      return {
        position: 'fixed',
        top: rect.bottom + 'px',
        left: rect.left + 'px',
      };

    case 'bottom-end':
      return {
        position: 'fixed',
        top: rect.bottom + 'px',
        left: rect.right + 'px',
        transform: 'translate(-100%, 0)'
      };

    case 'bottom':
      return {
        position: 'fixed',
        top: rect.bottom + 'px',
        left: rect.left + rect.width/2 + 'px',
        transform: 'translate(-50%, 0)'
      };

      case 'top':
        return {
          position: 'fixed',
          top: rect.top + 'px',
          left: rect.left + rect.width/2 + 'px',
          transform: 'translate(-50%, -100%)'
        };
  }
}